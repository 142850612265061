import React from "react";
import {
  Box,
  Text,
  Image,
  Button,
  ButtonGroup,
  Flex,
  SimpleGrid,
  Link,
} from "@chakra-ui/core";
import LogoImage from "./images/logo.png";
import { graphiteBlue, blue, green } from "./constants/colors";
import Snapshelf from "./images/appicons/snapshelf.png";
import Fliptastic from "./images/appicons/fliptastic.png";
import Flurry from "./images/appicons/flurry.png";
import Animata from "./images/appicons/animata.png";
import "./fonts/stylesheet.css";

function App() {
  return (
    <Box>
      <Image src={LogoImage} height={80} mt={5} ml={5} />

      <Text
        fontFamily="Circular Std"
        fontSize="48px"
        mt={50}
        textAlign="center"
        color={graphiteBlue}
      >
        Hey, I'm Ting
      </Text>
      <Text
        fontFamily="Circular Std"
        fontSize="48px"
        mt={12}
        ml={100}
        mr={100}
        textAlign="center"
        color={graphiteBlue}
      >
        A freelance app developer based in Sydney 🚀
      </Text>
      <Text
        mt={12}
        ml={12}
        mr={12}
        fontFamily="Circular Std"
        textAlign="center"
      >
        iOS (Swift/Objective-C) • React Native • React.JS • Node.JS
      </Text>
      <Flex alignItems="center" justifyContent="center" mt={12}>
        <ButtonGroup spacing={4}>
          <Button
            background={green}
            color="white"
            alignSelf="center"
            justifySelf="center"
            fontFamily="Circular Std"
            size={"lg"}
            onClick={() => {
              window.location.assign("mailto:hello@ting.rocks");
            }}
          >
            Email
          </Button>
          <Button
            background={blue}
            color="white"
            alignSelf="center"
            justifySelf="center"
            fontFamily="Circular Std"
            size={"lg"}
            onClick={() => {
              window.location.assign("https://www.linkedin.com/in/tingtc/");
            }}
          >
            LinkedIn
          </Button>
        </ButtonGroup>
      </Flex>
      <Flex
        backgroundColor={graphiteBlue}
        p={12}
        pb={20}
        mt={50}
        flexDirection="column"
        alignItems="center"
      >
        <Text
          fontFamily="Circular Std"
          fontSize="24px"
          ml={100}
          mr={100}
          textAlign="center"
          color={"white"}
        >
          Some of my own apps
        </Text>
        <SimpleGrid mt={12} columns={[1, 2, 3, 4]} spacing={12}>
          <Link
            href="https://apps.apple.com/us/app/snapshelf-text-reader-app/id1477627350"
            isExternal
          >
            <Image src={Snapshelf} borderRadius={26} />
          </Link>
          <Link
            href="https://apps.apple.com/us/app/fliptastic-easy-slideshows/id767542456"
            isExternal
          >
            <Image src={Fliptastic} borderRadius={26} />
          </Link>
          <Link
            href="https://apps.apple.com/us/app/flurry/id1087771144"
            isExternal
          >
            <Image src={Flurry} borderRadius={26} />
          </Link>
          <Link
            href="https://apps.apple.com/us/app/create-play-learn-animata/id725186720"
            isExternal
          >
            <Image src={Animata} borderRadius={26} />
          </Link>
        </SimpleGrid>
      </Flex>

      <Text
        fontFamily="Circular Std"
        fontSize="16px"
        mt={100}
        textAlign="center"
        color={graphiteBlue}
      >
        Taptical • ABN 32 081 273 527
      </Text>
      <Text
        fontFamily="Circular Std"
        fontSize="16px"
        mt={4}
        mb={12}
        textAlign="center"
        color={graphiteBlue}
      >
        Copyright © 2020 Ting Tze Chuen
      </Text>
    </Box>
  );
}

export default App;
